.focus-time-stats {
  text-align: center;
  padding: 0px;
  font-family: 'Arial', sans-serif; /* Consistent font styling */
}

.focus-time-stats p {
  font-size: 1.2rem;
  margin: 10px 0;
  padding-left: 0px;
}

.stats-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; /* Ensure elements wrap on smaller screens */
  padding: 20px;
  width: 100%;
}

.focus-time-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap; /* Add wrapping for smaller screens */
}

.stats-text {
  flex: 1;
  padding: 0px;
  padding-top: 20%;
  text-align: centre; /* Align text for better readability */
}

.charts {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  padding-top: 40px;
  padding-right: 5%;
}

.WeeklyGoal {
  padding-top: 30%;
}

.progress-container {
  position: relative; /* Adjust for better positioning */
  margin: 20px auto; /* Center-align the progress bar */
  margin-top: 10%;
  width: 80%; /* Set a relative width for responsiveness */
  max-width: 300px; /* Cap the width for large screens */
  height: 50px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.progress-bar {
  height: 100%;
  border-radius: 5px;
  transition: width 0.5s ease; /* Smooth animation for progress updates */
}

.progress-bar.goal {
  background-color: rgba(75, 192, 192, 1);
}

.progress-bar.remaining {
  background-color: rgba(255, 99, 132, 0.5);
}

.goal-met {
  position: relative;
  margin-top: 20px;
  background-color: rgba(75, 192, 192, 0.8);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.goal-met:hover {
  background-color: rgba(137, 43, 226, 0.753);
}

#Weekly {
  font-size: 14px;
  padding-left: 5%;
  text-align: center; /* Center-align for consistency */
}

/* Responsive Design */
@media (max-width: 768px) {
  .stats-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align for better appearance */
  }

  .stats-text {
    padding-top: 10%; /* Reduce padding for smaller screens */
    padding-bottom: 10%;
  }

  .charts {
    padding-right: 7%; /* Adjust padding */
    padding: 0px 10% 20px 20px;
  }

  .progress-container {
    width: 90%; /* Make progress bar fill available width */
    margin-top: 0px;
  }
}

@media (max-width: 480px) {
  .stats-text {
    font-size: 1rem; /* Adjust font size */
    padding: 10px;
  }

  .progress-container {
    height: 30px; /* Reduce height for smaller screens */
    margin: 30px auto; /* Center-align the progress bar */

  }

  .focus-time-stats p {
  font-size: 1.2rem;
  margin: 10px 0;
  padding-left: 0px;
}
  .goal-met {
    padding: 8px 16px; /* Compact button styling */
    font-size: 0.9rem; /* Smaller font size */
  }
}
