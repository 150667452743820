/* Whiteboard Container */
.whiteboard-container {
  position: relative;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Hide overflow */
  padding-top: 0;
}

/* Whiteboard Canvas */
.whiteboard-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff; /* White background */
  cursor: crosshair; /* Crosshair cursor for drawing */
}

/* Toolbox Styling */
.toolbox {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8; /* Slightly lighter background */
  padding: 10px 15px;
  border-radius: 50px; /* Rounded corners for the toolbox */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Enhanced shadow for better visibility */
  gap: 10px; /* Consistent spacing between elements */
  z-index: 100; /* Ensure it stays above the canvas */
}

/* Toolbox Icon Styling */
.toolbox-icon {
  margin: 0;
  padding: 10px;
  font-size: 20px; /* Reduced font size for better alignment */
  color: #333; /* Neutral color for icons */
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50%; /* Circular icon */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Circle dimensions */
  height: 40px;
  background-color: #e0e0e0; /* Neutral background */
}

.toolbox-icon:hover {
  color: #f0a500; /* Highlight color on hover */
  background-color: #d0d0d0; /* Subtle background change on hover */
}

.toolbox-icon.active {
  color: #f0a500; /* Highlight active icon */
  background-color: #c0c0c0; /* Slightly darker active background */
  box-shadow: 0 0 8px rgba(240, 165, 0, 0.5); /* Glow effect for active state */
}

/* Color Picker Styling */
.toolbox input[type="color"] {
  -webkit-appearance: none; /* Remove default browser styling */
  appearance: none; /* Remove default styling */
  border: none;
  width: 40px; /* Circle dimensions */
  height: 40px;
  border-radius: 50%; /* Circular input */
  background-color: #e0e0e0; /* Neutral background */
  cursor: pointer;
  margin: 0 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s ease;
}

.toolbox input[type="color"]:hover {
  transform: scale(1.1); /* Slight enlargement on hover */
}

/* Cross-browser Circular Color Swatch */
.toolbox input[type="color"]::-webkit-color-swatch,
.toolbox input[type="color"]::-moz-color-swatch,
.toolbox input[type="color"]::-ms-color-swatch {
  border-radius: 50%; /* Ensure color swatch is circular across browsers */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .toolbox {
    bottom: 20px;
    padding: 8px 12px;
    gap: 8px;
  }

  .toolbox-icon {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }

  .toolbox input[type="color"] {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 480px) {
  .toolbox {
    flex-direction: column;
    gap: 12px;
    padding: 10px;
  }

  .toolbox-icon {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }

  .toolbox input[type="color"] {
    width: 30px;
    height: 30px;
  }
}
