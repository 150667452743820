.notes-container {
  text-align: center;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; 
  width: 100%;
}

textarea {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #306433;
}

.saved-notes {
  margin-top: 20px;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  max-width: 500px;
}

.saved-notes ul {
  list-style-type: none;
  padding: 0;
}

.saved-notes li {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.saved-notes li button {
  margin-left: 2%;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.DelButton {
  background-color: #000000;
}

.DelButton:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.ViewButton {
  background-color: rgba(0, 0, 0, 0);
}

.ViewButton:hover {
  background-color: #000000d5;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: rgba(255, 250, 250, 0.89); /* Transparent background */
  color: #000000;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin: 0 20px; /* To give space on the sides */
}

.popup-content h3 {
  margin-bottom: 10px;
}

.popup-content button {
  margin-top: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.saved-note {
  text-align: left;  /* Align text to the left */
  margin-bottom: 10px; /* Space between notes */
  word-wrap: break-word; /* Break long words to fit within the width */
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .notes-container {
    padding: 20px;
  }
  
  textarea, .saved-notes {
    max-width: 100%;
  }

  .popup-content {
    width: 90%;
  }
}
