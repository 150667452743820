.App {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh; 
  overflow: hidden; 
  position: relative;
 
}

.Sidebar {
  width: 80px;
  background-color: #333;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; 
  z-index: 2; 
}

.content {
  flex: 1;
  padding: 0px;
  overflow-y: auto; 
  background-color: #f5f5f5; 
  position: relative; 
  overflow: hidden;
   
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.planner-container {
  width: 100%; 
  margin: 20px 0;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.timer-container {
  text-align: center;
  margin-top: 20px;
}

.timer-display h1 {
  font-size: 48px;
  margin: 20px 0;
}

.timer-input input {
  font-size: 18px;
  padding: 5px;
  width: 200px;
  margin-bottom: 10px;
}

.timer-buttons button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.timer-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.bg-options img {
  border: 2px solid transparent;
}

.bg-options img.selected-bg {
  border-color: #000; 
}
