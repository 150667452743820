/* Dashboard Layout */
.dashboard {
  display: flex;
  font-family: Arial, sans-serif;
  color: #fff;
  height: 100vh;
  padding-left: 5%;
}


.menu{
    padding-right: 10%;
    padding-left: 10%;
}

.menu h2 {
  font-size: 1.5em;
  color: #fff;
  padding-top: 120%;
  padding-left: 2%;

}

.menu ul {
  list-style-type: none;
  padding: 0;
  font-size: 1.1em;
}

.menu ul li {
  padding: 10px 0;
  cursor: pointer;
  color: #b3b3b3;
}

.menu ul li:hover {
  color: #fff;
}

.main-content {
  flex: 1;
  padding: 20px;
  margin: 1%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  border-radius: 25px;
}

.section {
  background-color: #333;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.section h3 {
  font-size: 1.4em;
  color: #fff;
}

.section p {
  color: #b3b3b3;
  margin: 10px 0;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-group label {
  color: #b3b3b3;
  font-size: 0.9em;
}

.input-group input {
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: none;
  background-color: #444;
  color: #fff;
}



.DelUser {
    color: #ffffff; 
    background-color: #ff4f4f; 
    border: 2px solid #ff0000; 
    border-radius: 8px; 
    font-weight: bold;
    padding: 10px 20px; 
    cursor: pointer; 
    transition: background-color 0.3s ease, transform 0.2s ease; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
    margin-left: 20%;
}

.DelUser:hover {
    background-color: #ff1f1f; 
    transform: scale(1.05); 
}

.DelUser:active {
    background-color: #cc0000; 
    transform: scale(0.98); 
}


button {
  padding: 10px 15px;
  margin-top: 10px;
  background-color: #45a049;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #666;
}

.main-content::-webkit-scrollbar-track {
    border-radius: 10px;
    margin: 20px 0;
}

.main-content::-webkit-scrollbar-thumb {
    background-color: #555; 
    border-radius: 10px;
}

.main-content::-webkit-scrollbar-thumb:hover {
    background-color: #ffffffa2; 
}

.menu ul li.active {
    color: white; 
    font-weight: bold; 
  }
  
.button-group {
    display: flex;
    justify-content: flex-start; 
    gap: 10px; 
    flex-wrap: nowrap; 
    width: auto; 
}

.Contact, .Report {
    padding: 10px 20px; 
    cursor: pointer; 
    background-color: #45a049; 
    color: white; 
    border: none; 
    border-radius: 5px; 
    font-size: 14px; 
    display: inline-flex; 
    align-items: center; 
    white-space: nowrap; 
    text-align: center; 
    flex-grow: 0; 
    margin-bottom: 2%;
}
.Contact{
    margin-right: 30%;
}

.hspace{
    margin-right: 13%;
    margin-left: 13%;
}

.Contact:hover, .Report:hover {
    background-color: #275729; 
}

.Buttons{
    margin-left: 25%;
    margin-right: 25%;
}

.email-container {
    display: flex;
    align-items: center;
  }
  
  .edit-email-btn {
    background-color: #45a049;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .email-edit-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: rgba(255, 250, 250, 0.89);
    padding: 10px;
    border-radius: 10px;
    max-width: 400px;
    width: 95%;
  }
  
  .send-otp-btn,
  .confirm-email-btn,
  .close-modal-btn {
    background-color: #45a049;
}

.Logout {
  color: #ffffff; 
  background-color: #ff4f4f; 
  border: 2px solid #ff0000; 
  border-radius: 8px; 
  font-weight: bold;
  padding: 10px 20px; 
  cursor: pointer; 
  transition: background-color 0.3s ease, transform 0.2s ease; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  margin-right: 9%;
  margin-left: 2%;
}

.DelUser:hover {
  background-color: #ff1f1f; 
  transform: scale(1.05); 
}

.DelUser:active {
  background-color: #cc0000; 
  transform: scale(0.98); 
}

.custom-placeholder::placeholder{
  color: white;
}

@media(max-width: 768px) {
  .dashboard {
    flex-direction: column;
    padding-left: 0;
    height: auto;
  }

  .menu {
    padding: 10px;
    padding-top: 20px;
    text-align: center;
  }

  .menu h2 {
    font-size: 1.2em;
    padding-top: 0;
    padding-left: 0;
  }

  .menu ul {
    font-size: 1em;
  }

  .menu ul li {
    padding: 8px 0;
  }

  .main-content {
    margin: 10px;
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 15%;
  }

    .section {
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 6px;
  }

  .section h3 {
    font-size: 1.2em;
  }

  .input-group label {
    font-size: 0.8em;
  }

  .input-group input {
    font-size: 0.9em;
    padding: 8px;
  }
  
    .hspace{
      margin-right: 223%;
      margin-left: 3%;
  }
  
  .button-group {
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
  }

  .Contact,
  .Report {
    font-size: 0.9em;
    padding: 8px 15px;
  }

    .Contact {
      margin-right: 5px;
  }
  
  .Buttons {
    margin-left: 0;
    margin-right: 0;
  }

  .email-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .edit-email-btn {
    margin: 10px 0 0 0;
  }

  .modal-content {
    max-width: 90%;
  }

  .Logout,
  .DelUser {
    font-size: 0.9em;
    padding: 8px 15px;
    margin: 10px 5px;
  }

  .menu ul li.active {
    font-size: 1.1em;
  }

  .menu ul li:hover {
    font-size: 1.1em;
  }
}
