/* Account.css */
.account-container {
  max-width: 90%;
  width: 400px;
  margin: 50px auto;
  padding: 20px;
  margin-top: 10%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  color: #fff; /* For better contrast on dark background */
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
  font-weight: bold;
}

input {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #45a049;
}

.toggle-link {
  text-align: center;
  margin-top: 20px;
}

.toggle-link button {
  background: none;
  border: none;
  color: #007BFF;
  cursor: pointer;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Ensure inputs stack on smaller screens */
}

.form-group {
  display: flex;
  flex-direction: column;
  flex-basis: 48%;
  flex-grow: 1; /* Ensures even spacing for inputs */
}

.form-group label {
  margin-bottom: 8px;
}

.form-group input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .account-container {
    width: 100%;
    padding: 15px;
    margin-top: 5%;
  }

  .form-row {
    flex-direction: column;
  }

  .form-group {
    flex-basis: 100%;
  }

  button {
    font-size: 16px;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .account-container {
    margin-top: 2%;
  }

  input {
    font-size: 14px;
  }

  button {
    font-size: 14px;
    padding: 10px;
  }
}
