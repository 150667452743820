/* Reset */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

/* General Background */
.background {
    padding-right: 10%;
}

/* Content Container */
.content {
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
    color: #fff;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Header Section */
.Header {
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 30px;
    padding-left: 18%;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    width: 60vw;
    float: left;
    height: 10vh;
    overflow: hidden;
    z-index: 1;
    color: #fff;
    text-align: center;
    font-size: 30px;
}

/* Account Section */
.Account {
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 40px;
    padding-top: 30px;
    padding-left: 20%;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    width: 60vw;
    float: left;
    height: 10vh;
    margin-left: 620px;
    overflow: hidden;
    position: fixed;
    z-index: 1;
    color: #fff;
    text-align: center;
}

.Account:hover {
    color: #f0a500;
}

/* Background Selector */
.background-selector {
    color: #fff;
}

/* Custom Dropdown */
.custom-dropdown {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    border-radius: 80px;
    border: 2px solid #ccc;
    background-color: transparent;
    color: #fff;
    appearance: none;
    outline: none;
    transition: all 0.3s ease;
    cursor: pointer;
    text-align: center;
}

.custom-dropdown:after {
    content: '▼';
    float: right;
    padding-right: 10px;
}

.custom-dropdown:hover {
    border-color: #888;
}

.custom-dropdown:focus {
    border-color: black;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Dropdown Options */
.custom-dropdown option {
    background-color: transparent;
    color: #000;
    border-radius: 8px;
}

/* Ripple Selector */
.ripple-selector {
    color: azure;
    padding-left: 50vw;
}

/* Settings Container */
.settings-container {
    display: flex;
    align-items: center;
    gap: 5%;
    padding-top: 19%;
    padding-left: 10%;
}

.custom-dropdown {
    margin-left: 10px;
}

/* Clock Section */
.clock {
    padding-top: 22%;
    font-size: 48px;
    font-family: 'Courier New', Courier, monospace;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
}

/* Mute Button */
.Mute {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 30px;
    color: #fff;
    position: absolute;
    margin-left: 44%;
    bottom: 60px;
}

.Mute:hover {
    color: #888;
}

/* Responsive Design */

/* Tablet Adjustments */
@media (max-width: 1024px) {
    .Header, .Account {
        font-size: 24px;
        width: 80vw;
        text-align: center;
    }

    .Account {
        margin-left: 0;
        padding-left: 10%;
    }

    .custom-dropdown {
        width: 180px;
        font-size: 14px;
    }

    .clock {
        font-size: 36px;
        padding-top: 20%;
    }

    .Mute {
        font-size: 26px;
        margin-left: 40%;
    }
}



/* Mobile Adjustments */
@media (max-width: 768px) {
    .Header, .Account {
        width: 100%;
        font-size: 30px;
        padding-left: 0px;
    }

    .content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .settings-container {
        gap: 20px;
        padding-right: 23px;
        padding-top: 60%;
    }

    .custom-dropdown {
        width: 150px;
        font-size: 14px;
    }

    .clock {
        font-size: 43px;
        padding-top: 90%;
    }

    .Mute {
        font-size: 20px;
        margin-left: 0;
        bottom: 40px;
    }
    .ripple-selector {
    color: azure;
    padding-left: 0vw;
    }
}
    

/* Small Mobile Adjustments */
@media (max-width: 480px) {
    .Header, .Account {
        font-size: 26px;
        padding-left: 0px;
    }

    .custom-dropdown {
        width: 120px;
        font-size: 12px;
    }

    .clock {
        font-size: 43px;
    }

    .Mute {
        font-size: 18px;
        bottom: 20px;
    }
    .ripple-selector {
    color: azure;
    padding-left: 0vw;
    }

}
