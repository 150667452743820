/* Planner Container */
.planner-container {
  width: 90%;
  margin: 0 auto;
  padding-right: 50px;
  height: 100%;
  overflow-y: auto;
  color: white;
}

/* Heading Styling */
h2 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5rem;
}

/* FullCalendar Buttons */
.fc-header-toolbar .fc-button {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.fc-button {
  color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.fc-button-primary {
  color: white;
  background-color: #4caf50;
}

.fc-button-primary:hover {
  background-color: #45a049;
  border-color: #45a049;
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 250, 250, 0.89);
  color: #000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 90%;
  max-width: 400px; /* Limit modal width */
}

.modal h2 {
  margin-bottom: 20px;
  color: #000;
  font-size: 1.2rem;
  text-align: center;
}

.modal label {
  display: block;
  margin-bottom: 10px;
  color: black;
  font-weight: bold;
}

.modal button {
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Submit Button */
#submit {
  background-color: #4caf50;
  color: #fff;
  border-color: #45a049;
}

#submit:hover {
  background-color: #45a049;
}

/* Cancel Button */
#cancel {
  background-color: #f44336;
  color: #fff;
  border-color: #d32f2f;
}

#cancel:hover {
  background-color: #d32f2f;
}

/* Form Time Inputs */
.modal form .time-input-start,
.modal form .time-input-end {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  margin-left: 20px;
}

.time-input-start,
.time-input-end {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

/* Labels */
.modal form label {
  font-weight: bold;
  color: #000;
}

/* Responsive Design */
@media (max-width: 768px) {
    h2 {
    color: white;
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  .modal {
    width: 95%;
    max-width: 300px;
  }

  .modal h2 {
    font-size: 1rem;
  }

  .fc-button {
    padding: 4px 6px;
    font-size: 0.9rem;
  }
  
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5em;
    font-size: x-small;
  }
  
  #submit,
  #cancel {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .planner-container {
    padding-right: 20px;
  }

  .modal {
    width: 100%;
    padding: 15px;
  }

  #submit,
  #cancel {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}
